@import "./globals/colors";
@import "./globals/responsive";

.home {
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  background: linear-gradient($red-light, $red);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  box-sizing: border-box;

  &__spacing {
    height: 30px;
  }

  &__logo {
    height: 80px;
    margin-bottom: 20px;
    margin-top: 30px;

    &__image {
      height: 100%;
    }
  }

  &__title {
    font-weight: bold;
    color: $white;
    font-size: 70px;
    margin-bottom: 15px;
    text-align: center;
  }

  &__subtitle {
    color: $white;
    font-size: 35px;
    margin-bottom: 40px;
    text-align: center;
  }

  &__description {
    width: 60%;

    &__content {
      margin-bottom: 10px;
      color: $white;
      text-align: center;

      & a {
        font-weight: bold;
        text-decoration: none;
        color: $white;
      }
    }
  }

  &__action {
    margin-bottom: 50px;

    &__button {
      color: $white;
      font-size: 26px;
      font-weight: bold;
      padding: 15px 40px;
      background-color: $blue;
      border: none;
      border-radius: 10px;
      transition: background-color 100ms;
      box-shadow: $shadow 0 15px 20px;

      &:hover {
        cursor: pointer;
        background-color: $blue-light;
      }
    }
  }

  @include medium {

    &__description {

      width: 85%;
    }
  }

  @include small {

    &__title {
      font-size: 60px;
    }

    &__subtitle {
      font-size: 20px;
    }

    &__description {

      width: 100%;
    }

    &__action {
      margin-bottom: 50px;

      &__button {
        font-size: 20px;
      }
    }
  }

  @include extra-small {
    padding: 0 5%;

    &__logo {
      height: 75px;
    }

    &__title {
      font-size: 30px;
    }

    &__subtitle {
      font-size: 16px;
      margin-bottom: 25px;
    }

    &__description {

      width: 100%;
    }

    &__action {
      margin-bottom: 50px;

      &__button {
        font-size: 16px;
      }
    }

    &__description {

      &__content {
        font-size: 14px;
      }
    }
  }
}
