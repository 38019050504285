@import './globals/colors';
@import "./globals/responsive";

.chart {
  height: 450px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__flux {
    display: flex;
  }

  &__incomes, &__outcomes {
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 5;

    &__label {
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid;
      font-weight: bold;
      position: relative;
    }

    &__income, &__outcome {
      width: 125px;
      height: 0;
      transition: height 200ms;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }

      &__label {
        text-align: center;
        color: $white;
        text-transform: uppercase;
        font-size: 10px;
        padding: 0 10px;
      }
    }

    &__box {
      position: absolute;
      width: 320px;
      padding: 20px;
      margin-top: 5px;
      border-radius: 5px;
      box-shadow: 0 3px 6px $shadow;
      display: none;
      text-align: center;
      background-color: $white;
      z-index: 5;

      i {
        position: absolute;
        margin-top: -25px;
        margin-left: 160px;

        &:after {
          content: url('../../public/quit.png');
        }
        &:hover:after {
          cursor: pointer;
        }
      }

      h1 {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        margin-bottom: 15px;
      }

      button {
        font-size: 14px;
        border-radius: 5px;
        padding: 7px 40px;
        background-color: $white;
        font-weight: bold;
        transition: box-shadow 100ms;

        &:hover {
          cursor: pointer;
          box-shadow: 0 5px 5px $shadow;
        }
      }
    }
  }

  .chart__incomes__label {
    color: $blue;
  }

  .chart__outcomes__label {
    color: $red;
  }

  .chart__incomes__income {
    background-color: $blue;
  }

  .chart__outcomes__outcome {
    background-color: $red-light;
  }

  .chart__incomes__box {
    margin-left: -380px;

    h1 {
      color: $blue;
    }

    button {
      border: 2px solid $blue;
      color: $blue;
    }
  }

  .chart__outcomes__box {
    margin-left: 145px;

    h1 {
      color: $red-light;
    }

    button {
      border: 2px solid $red-light;
      color: $red-light;
    }
  }

  .box-active {
    .chart__outcomes__outcome, .chart__incomes__income {
      box-shadow: inset 0 0 20px 10px $shadow-medium;

      &:hover {
        opacity: 1;
        cursor: initial;
      }
    }

  }

  @include medium {

    &__incomes, &__outcomes {
      z-index: initial;

      &__box {
        width: 270px;

        i {
          margin-left: 140px;
        }
      }
    }

    .chart__incomes__box {
      margin-left: 135px;
    }

    .chart__outcomes__box {
      margin-left: -325px;
    }
  }

  @include small {
    &__incomes, &__outcomes {

      &__box {

        i {
          margin-left: 0;
          top: 15px;
          right: -10px;
        }
      }
    }

    .chart__incomes__box, .chart__outcomes__box {
      margin-left: 0;
      width: inherit;
      top: 485px;
      left: 5%;
      right: 5%;
    }
  }
}
