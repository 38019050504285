@import './globals/colors';
@import "./globals/responsive";

.detail {
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  border: 1.5px solid $grey;
  padding: 35px 50px 15px 50px;
  border-radius: 15px;
  width: 75%;

  &__incomes, &__outcomes {
    width: 50%;
    padding: 0 15px;

    h3 {
      margin: 0 25% 35px 25%;
      width: 50%;
      padding: 5px 10px;
      text-align: center;
      color: $white;
      font-weight: bold;
    }
  }

  &__incomes {

    &__title {
      background-color: $blue;
    }
  }

  &__outcomes {

    &__title {
      background-color: $red;
    }
  }

  &__flux {
    margin-bottom: 15px;
    display: flex;
    width: 100%;

    &__infos {
      flex: 0.7;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__label {
        font-weight: bold;
      }

      &__amount {
        margin-top: 7px;
      }
    }

    &__link {
      flex: 0.3;
      transform: scale(0.5);
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &__icon {

        &:after {
          content: url('../../public/plus.png');
          transition: opacity 100ms;
        }

        &:hover:after {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
  }

  @include medium {
    width: 80%;
  }

  @include small {
    width: 88%;
  }

  @include extra-small {
    margin-top: 35px;
    flex-direction: column;
    padding: 45px 25px 15px 25px;
    width: 80%;

    &__incomes, &__outcomes {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        width: 60%;
        margin: 0 0 35px -25px;
      }
    }

    &__outcomes {
      margin-top: 35px;
    }
  }
}
