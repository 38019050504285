$red-light: #C9462C;
$red: #C52000;
$white: #FFFFFF;
$blue: #0098B6;
$blue-light: #4aabcc;
$black: #555555;
$shadow: rgba(0, 0, 0, 0.1);
$shadow-dark: rgba(0, 0, 0, 0.5);
$shadow-medium: rgba(0, 0, 0, 0.3);
$grey: #DCDCDC;
