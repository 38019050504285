@import './globals/colors';
@import "./globals/responsive";

.video {
  margin-top: 50px;
  width: 70%;

  h2 {
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    border-bottom: 1px solid;
    padding-bottom: 5px;
  }

  iframe {
    width: 100%;
    height: 400px;
  }

  @include small {
    width: 100%;
  }

  @include extra-small {
    width: 90%;
  }
}
