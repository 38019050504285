@import "./globals/colors";
@import "./globals/responsive";

.networks {
  text-align: center;

  .main & {
    filter: invert(0.5);
  }

  p {
    text-transform: uppercase;
    font-weight: bold;
    color: $white;
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
    border-bottom: 1px solid;
    padding-bottom: 5px;
  }

  a {
    margin: 0 10px;

    img {
      transition: transform 100ms;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }

  @include extra-small {
    width: 90%;

    a {
      margin: 0 5px;
    }

    img {
      width: 30px;
    }
  }
}
