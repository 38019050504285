*, button {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
}

html, body, #root, .app {
  height: 100%;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
