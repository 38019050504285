@import './globals/colors';
@import "./globals/responsive";

.main {
  margin-top: 45px;
  padding: 0 10% 15px 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .line {
    filter: invert(0.2);
  }

  &__scroll-top {
    opacity: 0;
    transition: opacity 300ms;
    z-index: 99;

    &:after {
      content: url('../../public/arrow-top.png');
      position: fixed;
      bottom: 50px;
      right: 50px;
      opacity: 0.7;
      transition: opacity 100ms;
    }

    &:hover:after {
      cursor: pointer;
      opacity: 1;
    }
  }

  &__infos {
    margin-top: 40px;
    width: 250px;
    padding: 10px 0;
    background-color: $white;
    border: 1px solid $black;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
    color: $black;
    opacity: 0.5;
    transition: opacity 300ms;

    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
  }

  @include small {
    padding: 0 5% 25px 5%;
  }

  @include extra-small {
    padding: 0 0 25px 0;

    &__infos {
      margin-top: 30px;
    }

    &__scroll-top {

      &:after {
        opacity: 1;
        right: 25px;
      }
    }
  }
}
