@import './globals/colors';

.nav {
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px $shadow;
  position: fixed;
  background-color: $white;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;

  & div {
    display: flex;

    &:hover {
      cursor: pointer;
    }
  }

  & h1 {
    margin-left: 15px;
    font-weight: bold;
    color: $blue;
    font-size: 20px;
    height: 100%;
  }

  & img {
    height: 25px;
  }
}
