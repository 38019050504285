@import './globals/colors';
@import "./globals/responsive";

.title {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;

  &--blue, &--red {
    font-weight: bold;
    font-size: 100px;
  }

  &--blue {
    color: $blue;
  }

  &--red {
    color: $red-light;
  }

  @include extra-small {
    margin-top: 15px;
    margin-bottom: 15px;

    &--blue, &--red {
      font-size: 50px;
    }
  }
}
